import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import { Container, Row, Col } from 'react-bootstrap';
import "./cards.scss"

export const fragment = graphql`
  fragment CardsSection on WpPage_Pagebuilder_Layouts_Cards {
	title
	text
	card {
		title
		text
		button {
			url
			title
			target
		}
	}
}
`

const Cards = ({title, text, card}) => (
	<section className="block cards">
		<Container fluid="xl">
				<Row>
					<Col md={{ span: 8, offset: 2 }} >
						{title && <h2>{title}</h2>}
						{text && <p dangerouslySetInnerHTML={{ __html: text }} />}
					</Col>
				</Row>
				<Row className="group">
					<Col md={{ span: 10, offset: 1 }}>
						<Row>
						{card.map((card, index) => (
							<Col key={index} md={6} className="column">
								<div className="card">
									<div className="test">
									{card.title && <h3>{card.title}</h3>}
									{card.text && <p dangerouslySetInnerHTML={{ __html: card.text }} />}
									</div>
									{card.button && <a href={card.button.url} className="button primary">{card.button.title}</a>}
								</div>
							</Col>
						))}
						</Row>
					</Col>
				</Row>
			</Container>
	</section>
)

export default Cards