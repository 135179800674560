import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import { Container, Row } from 'react-bootstrap';
import "./callout.scss";

export const fragment = graphql`
  fragment CalloutSection on WpPage_Pagebuilder_Layouts_Callout {
	title
	calloutButton {
		buttonStyle
		link {
			target
			title
			url
		}
	}
}
`

const Callout = ({title}) => (
	<section>
		<Container fluid="xl">
			<Row>
				{title && <h3>{title}</h3>}
				{/* {button.map(calloutButton => (
					<div key={button.id}>
						{link && <a href={link.url}>{link.title}</a>}
					</div>
				))} */}
			</Row>
		</Container>
	</section>
)

export default Callout