import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from 'react-bootstrap';
import "./media-grid.scss"
// import { gsap } from "gsap";
// import { Draggable } from "gsap/Draggable";
// import { InertiaPlugin } from "gsap/InertiaPlugin";

// gsap.registerPlugin(Draggable, InertiaPlugin);




// console.clear();

// var slideDelay = 1.5;
// var slideDuration = 1;
// var slides = document.querySelectorAll(".slide");
// var numSlides = slides.length;

// gsap.set(slides, {
//   xPercent:function(i, target){
//     return i * 100;
//   }
// });

// var wrap = wrapPartial(-100, (numSlides - 1) * 100);
// var timer = gsap.delayedCall(slideDelay, autoPlay).pause();
// var proxy = document.createElement("div");
// gsap.set(proxy, { x: "+=0" });
// var transform = proxy._gsTransform;
// var slideWidth = 0;
// var wrapWidth = 0;
// var animation = new gsap({repeat:-1});
// resize();

// var draggable = new Draggable(proxy, {
//   trigger: ".slides-container",
//   throwProps: true,
//   onPressInit: function() {
//     animation.pause();
//     timer.pause();
//     updateProgress();
//   },
//   onDrag: updateProgress,
//   onThrowUpdate: updateProgress,
//   onThrowComplete: function() {
//     timer.restart(true);
//   }
// });

// window.addEventListener("resize", resize);

// function animateSlides(direction) {
//   var progress = animation.progress() + direction / numSlides;
//   timer.pause();
//   animation.pause();
//   gsap.to(animation, slideDuration, {
//     progress:progress, 
//     overwrite:true,
//     modifiers:{
//       progress:function(value) {
//         return (value < 0 || value === 1 ? 1 : 0) + (value % 1);
//       }
//     },
//     onComplete:function() {
//       timer.restart(true);
//     }
//   });
// }

// function autoPlay() { 
//   animation.play();
//   gsap.fromTo(animation, 1, {timeScale:0}, {timeScale:1, ease: "power1.in"})
// }

// function updateProgress() {
//   animation.progress(transform.x / wrapWidth);
// }

// function resize() {
//   var progress = animation.progress();  
//   slideWidth = slides[0].offsetWidth;
//   wrapWidth = slideWidth * numSlides;
  
//   animation.progress(0).clear().to(slides, 100, {
//     xPercent: "+=" + (numSlides * 100),
//     ease: "none",
//     modifiers: {
//       xPercent: wrap
//     }
//   })
//   .to(proxy, 100, {x:wrapWidth, ease: "none"}, 0)
//   .progress(progress);
// }

// function wrapPartial(min, max) {  
//   var r = max - min;  
//   return function(value) {
//     var v = value - min;
//     return ((r + v % r) % r) + min;
//   }
// }

// Hamster($('.slides-container')[0]).wheel(function(event, delta, deltaX, deltaY) {
//   event.preventDefault();
//   animateSlides(delta/30);
// });








export const fragment = graphql`
  fragment MediaGridSection on WpPage_Pagebuilder_Layouts_MediaGrid {
	title
	text
	media {
		slide {
			id
			remoteFile {
				childImageSharp {
					fluid(maxWidth: 1200, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	}
}
`


const MediaGrid = ({title, text, media}) => {

	// // animations
	// const blue = useRef(null);
	
	// useEffect(() => {
  //   gsap.fromTo(
  //     [blue.current],
  //     0.5,
  //     { y: 18 },
  //     { y: -18, yoyo: true, repeat: -1 }
  //   );
  // }, []);
	
	return (
		<section className="block media-grid">
			<Container fluid="xl">
				<Row>
					<Col md={{ span: 8, offset: 2 }} >
						{title && <h2>{title}</h2>}
						{text && <p dangerouslySetInnerHTML={{ __html: text }} />}
					</Col>
				</Row>
			</Container>
			<div className="slides-container">
				{media && media.map((media, index) => (
					<div key={index} className="slide">
						{media.slide && media.slide.map((slide) => (
							<div className="img-wrap">
								<Img key={slide.id} fluid={slide.remoteFile.childImageSharp.fluid}
								style={{
									position: "absolute",
									left: 0,
									top: 0,
									width: "100%",
									height: "100%"
								}}/>}
								/>
							</div>
						))}
					</div>
				))}
			</div>
		</section>
	)
}

export default MediaGrid