import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Hero from "../../components/blocks/hero/hero"
// import SEO from "../../components/seo"
// import useSiteMetadata from "../../hooks/use-site-metadata"
import MediaWithText from "../../components/blocks/media-with-text/media-with-text"
import MediaGrid from "../../components/blocks/media-grid/media-grid"
import Columns from "../../components/blocks/columns/columns"
import Callout from "../../components/blocks/callout/callout"
import Cards from "../../components/blocks/cards/cards"

export const query = graphql`
  query page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      pageBuilder {
        layouts {
          ... HeroSection
          ... MediaWithTextSection
          ... MediaGridSection
          ... ColumnsSection
          ... CalloutSection
          ... CardsSection
        }
      }
    }
  }
`

export default ({ data }) => {
  // const { title, metaDescription  } = useSiteMetadata();
  const sections = data.page.pageBuilder.layouts;



  


  
  return (
    <Layout>
      
      <main>
      {sections.map(section => {
        const typeName = section.__typename;
        
        switch (typeName) {
          case 'WpPage_Pagebuilder_Layouts_Hero':
            return <Hero key={section.id} {...section} />
          
          case 'WpPage_Pagebuilder_Layouts_MediaWithText':
            return <MediaWithText key={section.id} {...section} />

          case 'WpPage_Pagebuilder_Layouts_Columns':
            return <Columns key={section.id} {...section} />

          case 'WpPage_Pagebuilder_Layouts_Callout':
            return <Callout key={section.id} {...section} />

          case 'WpPage_Pagebuilder_Layouts_MediaGrid':
            return <MediaGrid key={section.id} {...section} />

          case 'WpPage_Pagebuilder_Layouts_Cards':
            return <Cards key={section.id} {...section} />
          
          default:
            return <p>Please add page sections.</p>
        }
      })}
      </main>
    </Layout>
  );

  // return (
  //   <Layout>
  //     <main>
  //     {sections.map(section => {
  //       const typeName = section.__typename;

  //       const mappings = {
  //         'WpPage_Pagebuilder_Layouts_Hero': <Hero sectionId={section.id} />,
  //         'WpPage_Pagebuilder_Layouts_MediaWithText': <MediaWithText sectionId={section.id} />,
  //         'WpPage_Pagebuilder_Layouts_Columns': <Columns sectionId={section.id} />,
  //         'WpPage_Pagebuilder_Layouts_Callout': <Callout sectionId={section.id} />
  //       }
        
  //       return mappings.typeName
        
        
  //     })}
  //     </main>
  //   </Layout>

  // );



};

// export default Page;
